import React, { useEffect } from 'react';
import gsap from 'gsap';

function Loading() {
  useEffect(() => {
    const svg = document.getElementById("svg");
    const tl = gsap.timeline({ repeat: 0, yoyo: true });

    tl.to('.bluee', {
      duration: 1,
      attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
      ease: 'power2.in',
    }).to('.bluee', {
      duration: 1,
      attr: { d: 'M0 2S175 1 500 1s500 1 500 1V0H0Z' },
      ease: 'power2.out',
    });

    // Start SVG animation after the loading text is hidden
    tl.add(() => {
      gsap.to(svg, {
        duration: 0.8,
        attr: { d: 'M0 502S175 272 500 272s500 230 500 230V0H0Z' },
        ease: 'power2.in',
      });
    });

    // Clean up animation on unmount
    return () => {
      tl.kill(); // Kill the animation to prevent memory leaks
    };
  }, []); // Run once on component mount

  return (
    <div className="loader-wrap">
      <svg id="svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
        <path
          className="bluee"
          d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"
        ></path>
      </svg>
      <div className="loader-wrap-heading">
        <div className="load-text">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </div>
      </div>
    </div>
  );
}

export default Loading;
