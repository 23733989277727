import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import StudentsProjectLists from "../components/Student Portfolio/Student-Detailpage/ProjectLists";
import Footer from "../components/Layout/Footer";
import Loading from "../common/Loading";

function ProjectListsPage() {
  document.title = "ProjectListsPage";

  const backgroundColor = "";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header backgroundColor={backgroundColor} />
          <StudentsProjectLists />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default ProjectListsPage;
