import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Spinner from "../../assets/imgs/loop-loading-GIF-by-Derek-Fitz-unscreen.gif";
import Loader from "../../common/Loader";

function VideoSection() {
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoad = () => {
    setIsLoading(false);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };
  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Intersection ratio threshold at which the callback will be executed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimateHeading(true); // Start the animation only if it hasn't scrolled into view before
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);
  return (
    <section className="services section-padding sub-bg">
      <div className="container">
        <div className="sec-head mb-30">
          <h6 className="sub-title main-color mb-25 ">Watch our videos</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600  d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text">Our Latest Videos</span>
            </h2>
            <div class="ml-auto">
              <div class="swiper-arrow-control">
                <div class="swiper-button-prev" onClick={prevSlide}>
                  <span class="ti-arrow-circle-left"></span>
                </div>
                <div class="swiper-button-next" onClick={nextSlide}>
                  <span class="ti-arrow-circle-right"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Slider {...settings} ref={sliderRef}>
          <div class="swiper-wrapper">
            <div className="item-box radius-15">
              <div
                class="vid-con"
                onClick={() =>
                  openPopup(
                    "https://www.youtube.com/embed/62r3UPrOS9k?si=u4sO6RTwvuQFso-5"
                  )
                }
              >
                {/* {isLoading && <Loader />}
                <div style={{ display: isLoading ? "none" : "block" }}> */}
                <iframe
                  width="400"
                  height="315"
                  src="https://www.youtube.com/embed/62r3UPrOS9k?si=u4sO6RTwvuQFso-5"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style={{ pointerEvents: "none" }}
                  // onLoad={handleVideoLoad}
                ></iframe>
                {/* </div> */}
              </div>
              <h5 className="text-videosection">
                1 Lecture by Ar.Pradeep on his Residence Design during Residence
                case study visit of 1 year Students
              </h5>
              <p>
                Lecture by Ar.Pradeep on his Residence Design during Residence
                case study visit of 1year Students.
              </p>
            </div>
          </div>
          <div class="swiper-wrapper">
            <div className="item-box radius-15">
              <div
                class="vid-con"
                onClick={() =>
                  openPopup(
                    "https://www.youtube.com/embed/wjYZBDvdmpE?si=ldMESDAfQLZ_vJY2"
                  )
                }
              >
                {/* {isLoading && <Loader />}
                <div style={{ display: isLoading ? "none" : "block" }}> */}
                <iframe
                  width="400"
                  height="315"
                  src="https://www.youtube.com/embed/wjYZBDvdmpE?si=ldMESDAfQLZ_vJY2"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style={{ pointerEvents: "none" }}
                  // onLoad={handleVideoLoad}
                ></iframe>
                {/* </div> */}
              </div>
              <h5 className="text-videosection">
                1 Lecture by Ar.Pradeep on his Residence Design during Residence
                case study visit of 1 year Students
              </h5>
              <p>
                Lecture by Ar.Pradeep on his Residence Design during Residence
                case study visit of 1year Students.
              </p>
            </div>
          </div>

          <div class="swiper-wrapper">
            <div className="item-box radius-15">
              <div
                class="vid-con"
                onClick={() =>
                  openPopup(
                    "https://www.youtube.com/embed/k4dVgbuxBAw?si=8hSmHeesKNC44lGf"
                  )
                }
              >
                {/* {isLoading && <Loader />}
                <div style={{ display: isLoading ? "none" : "block" }}> */}
                <iframe
                  width="400"
                  height="315"
                  src="https://www.youtube.com/embed/k4dVgbuxBAw?si=8hSmHeesKNC44lGf"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style={{ pointerEvents: "none" }}
                  // onLoad={handleVideoLoad}
                ></iframe>
              </div>
              {/* </div> */}
              <h5 className="text-videosection">
                1 Lecture by Ar.Pradeep on his Residence Design during Residence
                case study visit of 1 year Students
              </h5>
              <p>
                Lecture by Ar.Pradeep on his Residence Design during Residence
                case study visit of 1year Students.
              </p>
            </div>
          </div>
        </Slider>
        {/* Close Button */}
        {showPopup && (
          <div className="close-btn-videosection" onClick={closePopup}>
            <i className="fas fa-times"></i>
          </div>
        )}
        {/* Video Popup */}
        {showPopup && (
          <div className="video-popup-videosection">
            <div className="video-container-videosection">
              <div className="video-content-videosection">
                <iframe
                  width="760"
                  height="515"
                  src={videoUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
export default VideoSection;
