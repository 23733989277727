import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    const handleScroll = () => {
        const scrollY = window.scrollY;
        gsap.to('.footer-container', { yPercent: -scrollY });
    };

    useEffect(() => {
        // Enable smooth scrolling animation for footer
        gsap.set('.footer-container', { yPercent: -50 });
        const uncover = gsap.timeline({ paused: true }).to('.footer-container', { yPercent: 0, ease: 'none' });

        ScrollTrigger.create({
            trigger: 'main',
            start: 'bottom bottom',
            end: '+=50%',
            animation: uncover,
            scrub: true,
        });

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='footer-common'>
        <footer className="clean-footer crev bg-img" data-background="assets/imgs/background/3.jpg" data-overlay-dark="10">
            <div className="container pb-0 pt-70 section-padding ontop">
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <h6 className="sub-title mb-30">About Us</h6>
                        <p className="opacity-8">
                            it's about creating spaces with sense, soul, sanctity, life, and
                            character and an architect evolves them by skilfully integrating
                            art, science, and engineering through an infinite ingenious
                            process.
                        </p>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="column">
                            <h6 className="sub-title mb-30">Useful Links</h6>
                            <ul className="rest fz-14">
                                <li className="mb-15 opacity-8">
                                    <a href="/">Learning events</a>
                                </li>
                                <li className="mb-15 opacity-8">
                                    <a href="#">Admissions</a>
                                </li>
                                <li className="mb-15 opacity-8">
                                    <a href="#">The People</a>
                                </li>
                                <li className="mb-15 opacity-8">
                                    <a href="#">Work with Us</a>
                                </li>
                                <li className="opacity-8">
                                    <a href="#">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="column">
                            <h6 className="sub-title mb-30">Downloads</h6>
                            <ul className="rest fz-14">
                                <li className="mb-15 opacity-8">
                                    <a href="#">Brochure</a>
                                </li>
                                <li className="mb-15 opacity-8">
                                    <a href="#">Course Details</a>
                                </li>
                                <li className="mb-15 opacity-8">
                                    <a href="#">Fee structure</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="column">
                            <div className="mb-10">
                                <h6 className="sub-title mb-30">Contact</h6>
                                <p className="opacity-8">
                                    PSG Institute of Architecture & Planning Neelambur, Coimbatore
                                    - 641062
                                </p>
                            </div>
                            <div className="mb-10">
                                <a className="opacity-8" href="mailto:info@psgiap.ac.in">
                                    <span>info@psgiap.ac.in </span>
                                </a>
                            </div>
                            <a className="opacity-8" href="tel:0422-3933570">
                                <span>0422-3933570 </span>
                            </a>{" "}
                            |
                            <a className="opacity-8" href="tel:0422-3933577">
                                <span>0422-3933577 </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
                    <div>
                        <ul className="rest d-flex align-items-center">
                            <li className="hover-this cursor-pointer hover-anim">
                                <motion.i className="fab fa-facebook-f" whileHover={{ scale: 2.2 }} />
                            </li>
                            <li className="hover-this cursor-pointer ml-30">
                                <motion.i className="fab fa-dribbble" whileHover={{ scale: 2.2 }} />
                            </li>
                            <li className="hover-this cursor-pointer ml-30">
                                <motion.i className="fab fa-linkedin-in" whileHover={{ scale: 2.2 }} />
                            </li>
                            <li className="hover-this cursor-pointer ml-30">
                                <motion.i className="fab fa-instagram" whileHover={{ scale: 2.2 }} />
                            </li>

                        </ul>
                    </div>
                    <div className="ml-auto">
                        <p className="fz-14">
                            Copyright © {new Date().getFullYear()} PSG IAP. All rights
                            reserved. Design :{" "}
                            <span>
                                <a href="https://mindmade.in/" target="_blank" rel="noreferrer">
                                    MindMade
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    );
};

export default Footer;
