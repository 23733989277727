import React from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import StudentProjects from '../components/Student Portfolio/Student-Detailpage/index';


function StudentProjectListPage () {
    document.title = "StudentProjects"

    const backgroundColor = "black"; 

  return (
    <div>
     <Header backgroundColor={backgroundColor} />
      <StudentProjects/>
      <Footer/>
    </div>
  )
}

export default StudentProjectListPage
