import { useEffect } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useNavigate } from "react-router-dom";


const StudentProjects = () => {
  const navigate = useNavigate();


  useEffect(() => {
    Fancybox.bind('[data-fancybox="gallery"]');
  }, []);



  const images = [
    {
      url: "https://source.unsplash.com/1280x720/?nature",
      caption: "Caption Images 1",
    },
    {
      url: "https://source.unsplash.com/1280x720/?food",
      caption: "Caption Images 2",
    },
    {
      url: "https://source.unsplash.com/1280x720/?food",
      caption: "Caption Images 3",
    },
    {
      url: "https://source.unsplash.com/1280x720/?travel",
      caption: "Caption Images 4",
    },
    {
      url: "https://source.unsplash.com/1280x720/?building",
      caption: "Caption Images 5",
    },
    {
      url: "https://source.unsplash.com/1280x720/?building",
      caption: "Caption Images 6",
    },
    {
      url: "https://source.unsplash.com/1280x720/?flower",
      caption: "Caption Images 7",
    },
    {
      url: "https://source.unsplash.com/1280x720/?animal",
      caption: "Caption Images 8",
    },
    {
      url: "https://source.unsplash.com/1280x720/?sport",
      caption: "Caption Images 9",
    },
    {
      url: "https://source.unsplash.com/1280x720/?sport",
      caption: "Caption Images 10",
    },
    {
      url: "https://source.unsplash.com/1280x720/?human",
      caption: "Caption Images 11",
    },
    {
      url: "https://source.unsplash.com/1280x720/?mountain",
      caption: "Caption Images 12",
    },
  ];

  return (
    <section className="main-grid">
      <div className="gallery-head">
        <h1 className="text-center h1 pb-40">Project Name</h1>
      </div>
      <div className="container">
        {images.map((image, index) => (
          <div className="card" key={index}>
            <div className="card-image">
              <a
                href={image.url}
                data-fancybox="gallery"
                data-caption={image.caption}
              >
                <img src={image.url} alt="Image Gallery" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StudentProjects;
