import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Test1 from "../../assets/imgs/intro/new1.webp";
import Test2 from "../../assets/imgs/intro/new2.webp";
import Particle from "../../common/Particles";
import landingVideo from "../../assets/imgs/video/landing.mp4";

const Testimonials = () => {
  const sliderRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [animateText, setAnimateText] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const section = document.querySelector(".testimonials");

      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        const isInSection =
          scrollTop > sectionTop - window.innerHeight + sectionHeight / 2;

        setAnimateText(isInSection);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };

  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
  };

  return (
    <section className="testimonials section-padding">
      <div id="particles-js"></div>
      <Particle />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 position-re view-tog-wrap">
            <Slider {...settings} ref={sliderRef}>
              <div className="item">
                <div className="content">
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-6 left-clumn ${
                        animateText ? "animated" : ""
                      }`}
                    >
                      <div className="caption d-slideup wow">
                        <h2 className="d-slideup wow">
                          <span className="tes-big-text">
                            <span className="up-text">
                              "Architecture should speak of its time and place,
                              but yearn for timelessness"
                            </span>
                          </span>
                        </h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 right-clumn"
                      onClick={() => openPopup(landingVideo)}
                    >
                      <div className="img-vid">
                        <img src={Test1} alt="" />
                        <div className="curv-butn">
                          <div className="icon">
                            <i className="fas fa-play"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-6 left-clumn ${
                        animateText ? "animated" : ""
                      }`}
                    >
                      <div className="caption d-slideup wow">
                        <h2 className="">
                          <span className="tes-big-text">
                            <span className="up-text">
                              "2 Architecture should speak of its time and
                              place, but yearn for timelessness"
                            </span>
                          </span>
                        </h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 right-clumn">
                      <div className="img-vid">
                        <img src={Test2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <div className="swiper-arrow-control control-abslout">
              <div className="swiper-button-prev" onClick={handlePrevSlide}>
                <span className="ti-arrow-circle-left"></span>
              </div>
              <div className="swiper-button-next" onClick={handleNextSlide}>
                <span className="ti-arrow-circle-right"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Close Button */}
      {showPopup && (
        <div className="close-btn-testimonial" onClick={closePopup}>
          <i className="fas fa-times"></i>
        </div>
      )}

      {/* Video Popup */}
      {showPopup && (
        <div className="video-popup">
          <div className="video-container-testimonial">
            <video controls autoPlay className="video-content">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </section>
  );
};

export default Testimonials;
