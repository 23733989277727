import React, { useState } from "react";
import background4 from "../../../assets/imgs/background/4.jpg";
import work1 from "../../../assets/imgs/works/1/1.webp";
import work2 from "../../../assets/imgs/works/1/2.webp";
import work3 from "../../../assets/imgs/works/1/3.webp";
import work4 from "../../../assets/imgs/works/1/4.webp";
import work5 from "../../../assets/imgs/works/1/5.webp";
import { useNavigate } from "react-router-dom";

function StudentsProjectLists() {
  const [activeFilter, setActiveFilter] = useState("*");
  const navigate = useNavigate();

  const handleProjectDetailpage =()=>{
    navigate("/ProjectDetail")
  }




  const projects = [
    { img: work1, name: "Project Name 1", student: "Student Name", categories: ["cat-3", "cat-2"] },
    { img: work2, name: "Project Name 2", student: "Student Name", categories: ["cat-2", "cat-4"] },
    { img: work3, name: "Project Name 3", student: "Student Name", categories: ["cat-1", "cat-3"] },
    { img: work4, name: "Project Name 4", student: "Student Name", categories: ["cat-2"] },
    { img: work5, name: "Project Name 5", student: "Student Name", categories: ["cat-3"] },
    { img: work1, name: "Project Name 6", student: "Student Name", categories: ["cat-1", "cat-4"] },
    { img: work2, name: "Project Name 7", student: "Student Name", categories: ["cat-3", "cat-4"] },
    { img: work3, name: "Project Name 8", student: "Student Name", categories: ["cat-2"] },
  ];

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <div>
      <main className="main-bg blog-wrap">
        <header
          className="page-header-cerv bg-img section-padding"
          style={{ backgroundImage: `url(${background4})`, backgroundSize: "cover", backgroundPosition: "center" }}
          data-overlay-dark="4"
        >
          <div className="container pt-100 ontop">
            <div className="text-left">
              <h2>Project List</h2>
              <div>
                <a href="/">Home</a>
                <span className="padding-rl-10">|</span>
                <span>project list</span>
              </div>
            </div>
          </div>
        </header>
      </main>
      <section className="work-stand section-padding sub-bg">
        <div className="container-xxl">
          <div className="row">
            {/* filter links */}
            <div className="filtering col-12 text-center">
              <div className="filter">
                <span className="text">Filter By :</span>
                <span onClick={() => handleFilterClick("*")} className={activeFilter === "*" ? "active" : ""}>
                  Show All
                </span>
                <span onClick={() => handleFilterClick("cat-1")} className={activeFilter === "cat-1" ? "active" : ""}>
                  Studio 1
                </span>
                <span onClick={() => handleFilterClick("cat-2")} className={activeFilter === "cat-2" ? "active" : ""}>
                  Studio 2
                </span>
                <span onClick={() => handleFilterClick("cat-3")} className={activeFilter === "cat-3" ? "active" : ""}>
                  Studio 3
                </span>
                <span onClick={() => handleFilterClick("cat-4")} className={activeFilter === "cat-4" ? "active" : ""}>
                  Studio 4
                </span>
              </div>
            </div>
          </div>
          <div className="gallery row stand-marg student-project-wrap">
            {projects
              .filter((project) => activeFilter === "*" || project.categories.includes(activeFilter))
              .map((project, index) => (
                <div key={index} className="col-lg-3 col-md-6 items" onClick={handleProjectDetailpage}>
                  <div className="item mt-40">
                    <div className="img">
                      <a className="pro-img">
                        <img src={project.img} alt="" />
                      </a>
                    </div>
                    <div className="cont mt-20">
                      <h5 className="fz-22">
                        <a className="pro-img">
                          {project.name}
                        </a>
                      </h5>
                      <p>
                        <a className="pro-img">
                          {project.student}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default StudentsProjectLists;
