import React, { useEffect, useState, useRef } from "react";
import Logo from "../../assets/imgs/logo.webp";
import Hmenu from "../../assets/imgs/h-menu.webp";
import $ from "jquery";
import { Menu } from "antd";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MarqueeText from "../../common/marquee";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion } from "framer-motion";



const { SubMenu } = Menu;


const Header = ({ backgroundColor }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    $(".navbar .search-form").on("click", ".search-icon", function () {
      $(".navbar .search-form").toggleClass("open");

      if ($(".navbar .search-form").hasClass("open")) {
        $(".search-form .close-search").slideDown();
      } else {
        $(".search-form .close-search").slideUp();
      }
    });

    $(".navbar").on("click", ".navbar-toggler", function () {
      $(".navbar .navbar-collapse").toggleClass("show");
    });

    $(window).on("scroll", function () {
      var bodyScroll = window.scrollY,
        navbar = $(".navbar"),
        logo = $(".navbar.change .logo > img");

      if (bodyScroll > 300) {
        navbar.addClass("nav-scroll");
        logo.attr("src", "assets/imgs/logo-dark.png");
      } else {
        navbar.removeClass("nav-scroll");
        logo.attr("src", "assets/imgs/logo-light.png");
      }
    });

    // Clean up jQuery events
    return () => {
      $(".navbar .search-form").off("click", ".search-icon");
      $(".navbar").off("click", ".navbar-toggler");
      $(window).off("scroll");
    };
  }, []);

  const handleMenuItemClick = (event) => {
    const submenu = event.target.nextSibling;
    if (submenu && submenu.classList.contains("sub-menu")) {
      submenu.classList.toggle("show");
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle submenu
  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };



  return (
    <>
    <nav
        className={`navbar ${isScrolled ? "nav-scroll" : ""}`}
        style={{
          background: !isScrolled ? backgroundColor : "white",
        }}
      >
        {/* Top section */}
        <div className="top-men">
          <div className="container-fluid">
            <div className="row align-items-center">
              <MarqueeText />
            </div>
          </div>
        </div>

        {/* Logo, Search Form, and Menu */}
        <div className="container h-top">
          {/* Logo */}
          <a className="logo" href="/">
            <img src={isScrolled ? Hmenu : Logo} alt="logo" />
          </a>

          {/* Search Form */}
          <div className="search-form ml-auto">
            <div className="form-group">
              <input type="text" name="search" placeholder="Search" />
              <button>
                <span className="pe-7s-search"></span>
              </button>
            </div>
            <div className="search-icon">
              <span className="pe-7s-search open-search"></span>
              <span className="pe-7s-close close-search"></span>
            </div>
          </div>

          {/* Menu */}
          <div className="topnav d-flex">
            <div
              className="menu-icon cursor-pointer"
              onClick={handleMenuToggle}
            >
              <span className="icon ti-menu"></span>
              Menu
            </div>
            <ul className="rest d-flex align-items-center social-icons">
              <li className="hover-this cursor-pointer">
                <a href="#0" className={isScrolled ? "" : "white"}>
                <motion.i className="fab fa-facebook-f" whileHover={{ scale: 1.6 }} />
                </a>
              </li>
              <li className="hover-this cursor-pointer ml-30">
                <a href="#0" className={isScrolled ? "" : "white"}>
                <motion.i className="fab fa-linkedin-in" whileHover={{ scale: 1.6 }} />
                </a>
              </li>
              <li className="hover-this cursor-pointer ml-30">
                <a href="#0" className={isScrolled ? "" : "white"}>
                <motion.i className="fab fa-instagram" whileHover={{ scale: 1.6 }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <SideNavigation
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </>
  );
};

export default Header;
