function MarqueeText() {
  return (
    <div>
      <div className="text-wrapper-marque col-md-9">
        <h6 className="marque-scroll-text">
          TEXT 1 <a>REGISTER HERE</a>
        </h6>
        <h6 className="marque-scroll-text">
          TEXT 2 <a>REGISTER HERE</a>
        </h6>
        <h6 className="marque-scroll-text">
          TEXT 3 <a>REGISTER HERE</a>
        </h6>
      </div>

      <div className="code-wrap col-md-3 text-right">
        <p className="static-text">
          TNEA Counselling code: <span>2379</span>
        </p>
      </div>
    </div>
  );
}
export default MarqueeText;
