import React from 'react'
import Header from '../components/Layout/Header'
import Gallery from '../components/News&Events/DetailPageNews&Events/index'
import Footer from '../components/Layout/Footer'

function GalleryPage() {
    document.title = "Gallery"

    const backgroundColor = "#2b24245e"; 

  return (
    <div>
     <Header backgroundColor={backgroundColor} />
      <Gallery />
      <Footer/>
    </div>
  )
}

export default GalleryPage
