import React, { useEffect, useState, useRef } from "react";
import Banner from "../assets/imgs/video/landing.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img1 from "../assets/imgs/background/2.jpg";
import Img2 from "../assets/imgs/background/4.jpg";
import Img3 from "../assets/imgs/background/8.jpg";



function BannerVideo() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
      };


  return (
    <div>
              {/* Banner video */}
      <section className="banner-wrap">
        <div className="video-wrap">
          <div className="bg-video-wrap">
            <video src={Banner} loop muted autoPlay />
          </div>
        </div>
        <div className="banner-content">
          <div className="main-header bg-img">
            <div className="container ontop">
              <div className="lg-text">
                <div className="d-flex">
                  <h1>
                    PSG Institute of <br />
                    Architecture and Planning
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       {/* Banner Images slider */}
      {/* <section className="banner-wrap">
        <div className="video-wrap">
          <div className="bg-video-wrap">
            <Slider {...settings}>
              <div>
                <img src={Img1} alt="Slide 1" />
              </div>
              <div>
                <img src={Img2} alt="Slide 2" />
              </div>
              <div>
                <img src={Img3} alt="Slide 3" />
              </div>
            </Slider>
          </div>
        </div>
        <div className="banner-content">
          <div className="main-header bg-img">
            <div className="container ontop">
              <div className="lg-text">
                <div className="d-flex">
                  <h1>
                    PSG Institute of <br />
                    Architecture and Planning
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
    
  )
}

export default BannerVideo