import React, { useRef, useState, useEffect } from "react";
import PrincipalImage from "../../assets/imgs/intro/2.webp";
import PSGvideo from "../../assets/imgs/video/psg.mp4";
import { Link } from "react-router-dom";

function AboutPrincipal() {
  const [showTextAnimation, setShowTextAnimation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };

  const closePopup = () => {
    setVideoUrl("");
    setShowPopup(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const section = document.querySelector(".about");

      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        const isInSection =
          scrollTop > sectionTop - window.innerHeight + sectionHeight / 2;

        setShowTextAnimation(isInSection);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="about sub-bg" data-scroll-index="2">
      <div className="container section-padding">
        <div className="row md-marg manage-wrap">
          <div className="col-lg-6">
            <div
              className={`cont md-mb50 ${showTextAnimation ? "animate" : ""}`}
            >
              <h6 className="sub-title main-color mb-15">Principal Message</h6>
              <h2 className="fw-600 d-rotate wow">
                <span className="rotate-text">Prof. Ar.G.TAMILVANAN</span>
              </h2>
              <div className="d-flex align-items-end mt-50">
                <div>
                  <p>
                    Prof.G.Tamilvanan is an Architect, Urban Planner with around
                    19 years of professional and academic expertise in the
                    fields of Architecture, Urban Planning, Urban Designing, and
                    Architectural Pedagogy. Ar.G.Tamilvanan received his
                    undergraduate degree from the University of Madras and his
                    Master's degree in Urban Planning from the Anna University
                    School of Architecture and Planning. In the beginning of his
                    career, he was associated with one of the renowned
                    architectural firm OCI Architects.
                  </p>
                  <div className="mt-30">
                    <Link to="/" className="go-more">
                      <span className="text">Read More </span>
                      <span className="icon ti-arrow-circle-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-vid" onClick={() => openPopup(PSGvideo)}>
              <img src={PrincipalImage} alt="" />
              <div className="curv-butn">
                <div className="icon">
                  <i className="fas fa-play"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Close Button */}
      {showPopup && (
        <div className="close-btn-testimonial" onClick={closePopup}>
          <i className="fas fa-times"></i>
        </div>
      )}
      {/* Video Popup */}
      {showPopup && (
        <div className="video-popup">
          <div className="video-container-testimonial">
            <video controls autoPlay className="video-content">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </section>
  );
}

export default AboutPrincipal;
