import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import NewsandEvents from "../components/News&Events/NewsandEvents";
import VideoSection from "../components/Latest Video/VideoSection";
import Testimonials from "../components/Testimonial/Testimonials";
import ImageComponent from "../components/Background Image/BgImage";
import PortfolioTab from "../components/Student Portfolio/PortfolioTab";
import AboutPrincipal from "../components/Principal/AboutPrincipal";
import BlogListCrev from "../components/Blog/Blog";
import Footer from "../components/Layout/Footer";
import Loading from "../common/Loading"
import BannerVideo from "../common/BannerVideo";

function Home() {
  document.title = "Home";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {loading ? <Loading /> : (
        <>
          <Header />
          <BannerVideo/>
          <NewsandEvents />
          <VideoSection />
          <Testimonials />
          <ImageComponent />
          <PortfolioTab />
          <AboutPrincipal />
          <BlogListCrev />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;
