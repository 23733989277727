import React, { useState, useEffect, useRef } from "react";
import Pro1 from "../../assets/imgs/works/1/1.webp";
import Pro2 from "../../assets/imgs/works/1/2.webp";
import Pro3 from "../../assets/imgs/works/1/3.webp";
import Pro4 from "../../assets/imgs/works/1/4.webp";
import Pro5 from "../../assets/imgs/works/1/5.webp";
import { useNavigate } from "react-router-dom";

function PortfolioTab() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("tab-1");
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  const handleTextHover = (tabId) => {
    setCurrentTab(tabId);
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Intersection ratio threshold at which the callback will be executed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimateHeading(true); // Start the animation only if it hasn't scrolled into view before
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);

  return (
    <section
      className="portfolio-tab crev section-padding"
      data-scroll-index="3"
    >
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Student Portfolio</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 ls1 d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text">Our Student Working on</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="glry-img">
              <div
                id="tab-1"
                className={`bg-img tab-img ${
                  currentTab === "tab-1" ? "current" : ""
                }`}
                style={{ backgroundImage: `url(${Pro1})` }}
              ></div>
              <div
                id="tab-2"
                className={`bg-img tab-img ${
                  currentTab === "tab-2" ? "current" : ""
                }`}
                style={{ backgroundImage: `url(${Pro2})` }}
              ></div>
              <div
                id="tab-3"
                className={`bg-img tab-img ${
                  currentTab === "tab-3" ? "current" : ""
                }`}
                style={{ backgroundImage: `url(${Pro3})` }}
              ></div>
              <div
                id="tab-4"
                className={`bg-img tab-img ${
                  currentTab === "tab-4" ? "current" : ""
                }`}
                style={{ backgroundImage: `url(${Pro4})` }}
              ></div>
              <div
                id="tab-5"
                className={`bg-img tab-img ${
                  currentTab === "tab-5" ? "current" : ""
                }`}
                style={{ backgroundImage: `url(${Pro5})` }}
              ></div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 content">
            <div
              className="cluom mb-30 current wow fadeInUp"
              data-wow-delay=".2s"
              data-tab="tab-1"
              onMouseEnter={() => handleTextHover("tab-1")}
            >
              <div className="info">
                <h6 className="sub-title opacity-7">Student Name 1 </h6>
                <h4>Project Name 1</h4>
              </div>
              <div className="img">
                <img src={Pro1} alt="" />
              </div>
              <div className="more text-u ls1 fz-12">
                <a href="/ProjectDetail">View Project </a>
              </div>
            </div>
            <div
              className="cluom mb-30  wow fadeInUp"
              data-wow-delay=".3s"
              data-tab="tab-2"
              onMouseEnter={() => handleTextHover("tab-2")}
            >
              <div className="info">
                <h6 className="sub-title opacity-7">Student Name 2</h6>
                <h4>Project Name 2</h4>
              </div>
              <div className="img">
                <img src={Pro2} alt="" />
              </div>
              <div className="more text-u ls1 fz-12">
                <a href="/ProjectDetail">View Project </a>
              </div>
            </div>
            <div
              className="cluom mb-30  wow fadeInUp"
              data-wow-delay=".5s"
              data-tab="tab-3"
              onMouseEnter={() => handleTextHover("tab-3")}
            >
              <div className="info">
                <h6 className="sub-title opacity-7">Student Name 3</h6>
                <h4>Project Name 3</h4>
              </div>
              <div className="img">
                <img src={Pro3} alt="" />
              </div>
              <div className="more text-u ls1 fz-12">
                <a href="/ProjectDetail">View Project </a>
              </div>
            </div>
            <div
              className="cluom mb-30   wow fadeInUp"
              data-wow-delay=".7s"
              data-tab="tab-4"
              onMouseEnter={() => handleTextHover("tab-4")}
            >
              <div className="info">
                <h6 className="sub-title opacity-7">Student Name 4</h6>
                <h4>Project Name 4</h4>
              </div>
              <div className="img">
                <img src={Pro4} alt="" />
              </div>
              <div className="more text-u ls1 fz-12">
                <a href="/ProjectDetail">View Project </a>
              </div>
            </div>
            <div
              className="cluom wow fadeInUp"
              data-wow-delay=".9s"
              data-tab="tab-5"
              onMouseEnter={() => handleTextHover("tab-5")}
            >
              <div className="info">
                <h6 className="sub-title opacity-7">Student Name 5</h6>
                <h4>Project Name 5</h4>
              </div>
              <div className="img">
                <img src={Pro5} alt="" />
              </div>
              <div className="more text-u ls1 fz-12">
                <a href="/ProjectDetail">View Project </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flr mt-30">
          <a href="/StudentsProjectLists" className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right"></span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default PortfolioTab;
