import React, { useState, useRef, useEffect } from "react";
import Blogimg from "../../assets/imgs/blog/blog2.webp";
import Blogimg4 from "../../assets/imgs/blog/blog4.webp";

function BlogListCrev() {
  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Intersection ratio threshold at which the callback will be executed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimateHeading(true); // Start the animation only if it hasn't scrolled into view before
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);

  return (
    <section className="blog-list-crev section-padding">
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Our Blog</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600  d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text">
                Read latest news - Archigazette
              </span>
            </h2>
          </div>
        </div>

        {/* First Item */}
        <div className="item sub-bg2 wow fadeInUp" data-wow-delay=".1s">
          <div className="row">
            <div className="col-lg-3">
              <div className="info md-mb30">
                <div className="d-flex align-items-center">
                  <div className="author-info">
                    <span className="fz-13 opacity-8 mb-5">Posted by</span>
                    <h6 className="fz-18">Name</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cont">
                <h5 className="mb-10 underline">
                  <a href="#">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center justify-end">
              <div className="ml-auto">
                <span className="date sub-title fz-13 opacity-8 mb-30">
                  18 February 2024
                </span>
              </div>
            </div>
          </div>
          <div
            className="background bg-img valign text-center"
            style={{ backgroundImage: `url(${Blogimg4})` }}
            data-overlay-dark="4"
          >
            <div className="more ontop full-width">
              <a href="#">
                <span>
                  Read More <i className="fas fa-arrow-right ml-10"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        {/* End of First Item */}

        {/* Second Item */}
        <div className="item wow fadeInUp" data-wow-delay=".3s">
          <div className="row">
            <div className="col-lg-3">
              <div className="info md-mb30">
                <div className="d-flex align-items-center">
                  <div className="author-info">
                    <span className="fz-13 opacity-8 mb-5">Posted by</span>
                    <h6 className="fz-18">Name</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cont">
                <h5 className="mb-10 underline">
                  <a href="#">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo.
                  </a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center justify-end">
              <div className="ml-auto">
                <span className="date sub-title fz-13 opacity-8 mb-30">
                  27 November 2023
                </span>
              </div>
            </div>
          </div>
          <div
            className="background bg-img valign text-center"
            style={{ backgroundImage: `url(${Blogimg})` }}
            data-overlay-dark="4"
          >
            <div className="more ontop full-width">
              <a href="#">
                <span>
                  Read More <i className="fas fa-arrow-right ml-10"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        {/* End of Second Item */}

        {/* Third Item */}
        <div className="item sub-bg2 wow fadeInUp" data-wow-delay=".5s">
          <div className="row">
            <div className="col-lg-3">
              <div className="info md-mb30">
                <div className="d-flex align-items-center">
                  <div className="author-info">
                    <span className="fz-13 opacity-8 mb-5">Posted by</span>
                    <h6 className="fz-18">Name</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cont">
                <h5 className="mb-10 underline">
                  <a href="#">
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident.
                  </a>
                </h5>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center justify-end">
              <div className="ml-auto">
                <span className="date sub-title fz-13 opacity-8 mb-30">
                  6 August 2023
                </span>
              </div>
            </div>
          </div>
          <div
            className="background bg-img valign text-center"
            style={{ backgroundImage: `url(${Blogimg4})` }}
            data-overlay-dark="4"
          >
            <div className="more ontop full-width">
              <a href="#">
                <span>
                  View More <i className="fas fa-arrow-right ml-10"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
        {/* End of Third Item */}

        <div className="flr mt-30">
          <a href="#" className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right"></span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default BlogListCrev;
