import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./assets/css/plugins/pe-icon-7-stroke.css";
import "./assets/css/plugins/fontawesome-all.min.css";
import "./assets/css/plugins/justifiedGallery.min.css";
import "./assets/css/plugins/magnific-popup.css";
import "./assets/css/plugins/YouTubePopUp.css";
import "./assets/css/plugins/swiper.min.css";
import "./assets/css/plugins/themify-icons.css";
import "./assets/css/base.css";
import "./assets/css/style.css";
import "./assets/css/plugins.css";
import GlobalStyle from "./common/GlobalStyle";
import Home from "./Pages/Home";
import Cursor from "./common/Cursor";
import GalleryPage from "./Pages/Gallery";
import StudentProjectListPage from "./Pages/StudentProjects";
import ProjectListsPage from "./Pages/ProjectListsPage"



function App() {
  return (
    <BrowserRouter>
      <div className="crev-agency">
        <div className="App">
          <GlobalStyle />
          <Cursor />
          <div id="smooth-content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/Gallery" element={<GalleryPage />} />
              <Route exact path="/StudentsProjectLists" element={<ProjectListsPage/>}/>
              <Route exact path="/ProjectDetail" element={<StudentProjectListPage/>}/>
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
 