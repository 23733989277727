import React from "react";
import { Parallax } from "react-parallax";
import Bgimg from "../../assets/imgs/background/3.webp";

const ImageComponent = () => {
  return (
    <div className="parallax-container">
      <div className="parallax-image"  style={{ backgroundImage: `url(${Bgimg})` }} />
      
      <div className="content"></div>
    </div>
  );
};
export default ImageComponent;
