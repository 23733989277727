import React, { useState } from "react";
import Hmenu from "../../assets/imgs/h-menu.webp";
import menuImage1 from "../../assets/imgs/menu/1.webp";
import menuImage2 from "../../assets/imgs/menu/2.webp";
import menuImage3 from "../../assets/imgs/menu/3.webp";
import menuImage4 from "../../assets/imgs/menu/4.webp";
import menuImage5 from "../../assets/imgs/menu/5.webp";
import menuImage6 from "../../assets/imgs/menu/6.webp";
import menuImage7 from "../../assets/imgs/menu/7.webp";
import menuImage8 from "../../assets/imgs/menu/8.webp";
import Particles1 from "../../common/Particles1";
import { animated, useSpring } from "@react-spring/web"; 

const SideNavigation = ({
  isMenuOpen,
  handleMenuToggle,
  openKeys,
  onOpenChange,
}) => {
  const [clickedMenuItem, setClickedMenuItem] = useState(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);

  const handleMenuItemHover = (menuItem) => {
    setHoveredMenuItem(menuItem);
  };

  const handleMenuItemLeave = () => {
    setHoveredMenuItem(null);
  };

  const handleMenuItemClick = (menuItem) => {
    if (clickedMenuItem === menuItem) {
      setClickedMenuItem(null);
    } else {
      setClickedMenuItem(menuItem);
    }
  };

  const menuTextAnimation = useSpring({
    transform: isMenuOpen ? "translateX(0)" : "translateX(-100%)",
    opacity: isMenuOpen ? 1 : 0,
  });

  return (
    <div id="mySidenav" className={`sidenav ${isMenuOpen ? "open" : ""}`}>
      <a className="closebtn close-menu" onClick={handleMenuToggle}>
        &times;
      </a>
      <div className="hamenu">
        <div className="">
          <Particles1 />
          <div className="row">
            <div className="col-lg-12">
              <div className="menu-links">
                <ul className="main-menu rest">
                  <li className="ham-menu">
                    <div className="menu-logo">
                      <img src={Hmenu} alt="Menu Logo" />
                    </div>
                  </li>
                  <li
                    className="menu1"
                    onMouseEnter={() => handleMenuItemHover("menu1")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu1")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="Home">
                          Home
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>

                    {isMenuOpen && !hoveredMenuItem && !clickedMenuItem && (
                      <div className="image-hover-img menu-image-default">
                        <img src={menuImage1} alt="Menu Image 1" />
                      </div>
                    )}
                    {hoveredMenuItem === "menu1" && isMenuOpen && (
                      <div className="image-hover-img menu-image-1">
                        <img src={menuImage1} alt="Menu Image 1" />
                      </div>
                    )}
                    {clickedMenuItem === "menu1" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            The Trust
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 2 */}
                  <li
                    className="menu2"
                    onMouseEnter={() => handleMenuItemHover("menu2")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu2")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="About Us">
                          About Us
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu2" && isMenuOpen && (
                      <div className="image-hover-img menu-image-2">
                        <img src={menuImage2} alt="Menu Image 2" />
                      </div>
                    )}
                    {clickedMenuItem === "menu2" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            The Trust
                          </a>
                        </li>
                        <li>
                          <a className="sub-link" href="#">
                            Administration
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 3 */}
                  <li
                    className="menu3"
                    onMouseEnter={() => handleMenuItemHover("menu3")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu3")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="The people">
                          The people
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu3" && isMenuOpen && (
                      <div className="image-hover-img menu-image-3">
                        <img src={menuImage3} alt="Menu Image 3" />
                      </div>
                    )}
                    {clickedMenuItem === "menu3" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            Principal
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 4 */}
                  <li
                    className="menu4"
                    onMouseEnter={() => handleMenuItemHover("menu4")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu4")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="Pedagogy">
                          Pedagogy
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu4" && isMenuOpen && (
                      <div className="image-hover-img menu-image-4">
                        <img src={menuImage4} alt="Menu Image 4" />
                      </div>
                    )}
                    {clickedMenuItem === "menu4" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            Programs
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 5 */}
                  <li
                    className="menu5"
                    onMouseEnter={() => handleMenuItemHover("menu5")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu5")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="Learning events">
                          Learning events
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu5" && isMenuOpen && (
                      <div className="image-hover-img menu-image-5">
                        <img src={menuImage5} alt="Menu Image 5" />
                      </div>
                    )}
                    {clickedMenuItem === "menu5" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            Monthly Lecture Series
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 6 */}
                  <li
                    className="menu6"
                    onMouseEnter={() => handleMenuItemHover("menu6")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu6")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="Admissions">
                          Admissions
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu6" && isMenuOpen && (
                      <div className="image-hover-img menu-image-6">
                        <img src={menuImage6} alt="Menu Image 6" />
                      </div>
                    )}
                    {clickedMenuItem === "menu6" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            Curricular works
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 7 */}
                  <li
                    className="menu7"
                    onMouseEnter={() => handleMenuItemHover("menu7")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu7")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="Life at campus">
                          Life at campus
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu7" && isMenuOpen && (
                      <div className="image-hover-img menu-image-7">
                        <img src={menuImage7} alt="Menu Image 7" />
                      </div>
                    )}
                    {clickedMenuItem === "menu7" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            Careers info
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Menu item 8 */}
                  <li
                    className="menu8"
                    onMouseEnter={() => handleMenuItemHover("menu8")}
                    onMouseLeave={handleMenuItemLeave}
                    onClick={() => handleMenuItemClick("menu8")}
                  >
                    <div className="o-hidden">
                      <animated.div
                        className="link cursor-pointer dmenu"
                        style={menuTextAnimation}
                      >
                        <span className="fill-text" data-text="Work with us">
                          Work with us
                        </span>
                        <i className="menu-i-left"></i>
                      </animated.div>
                    </div>
                    {hoveredMenuItem === "menu8" && isMenuOpen && (
                      <div className="image-hover-img menu-image-8">
                        <img src={menuImage8} alt="Menu Image 8" />
                      </div>
                    )}
                    {clickedMenuItem === "menu8" && (
                      <ul className="sub-menu">
                        <li>
                          <a className="sub-link" href="#">
                            College Code
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
