import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import news1 from "../../assets/imgs/news/news-1.webp";
import news2 from "../../assets/imgs/news/news-2.webp";
import news3 from "../../assets/imgs/news/news-3.webp";

function NewsandEvents() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  const handleOpenModal = (news) => {
    if (news && news.images.length === 1) {
      setSelectedNews(news.images[0]);
      setShowModal(true);
    } else {
      navigate("/Gallery");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Intersection ratio threshold at which the callback will be executed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasScrolledIntoView) {
            setAnimateHeading(true); // Start the animation only if it hasn't scrolled into view before
            setHasScrolledIntoView(true);
          }
        }
      });
    }, options);

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    // Cleanup
    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [hasScrolledIntoView]);
  return (
    <section className="services-boxs section-padding pt-50">
      <div className="container">
        <h6 className="sub-title main-color mb-25">News & Events</h6>
        <div className="sec-head mb-20">
          <div className="bord pt-20 bord-thin-top d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 ls1 ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              Our Latest News
            </h2>
          </div>
        </div>

        <div className="row pt-30">
          {[
            {
              images: [
                {
                  image: news1,
                  alt: "News Image 1",
                },
              ],
            },
            {
              images: [
                {
                  image: news2,
                  alt: "News Image 1",
                },
              ],
            },
            {
              images: [
                {
                  image: news3,
                  alt: "News Image 1",
                },
                {
                  image: news2,
                  alt: "News Image 2",
                },
                {
                  image: news3,
                  alt: "News Image 3",
                },
              ],
            },
            {
              images: [
                {
                  image: news1,
                  alt: "News Image 1",
                },
                {
                  image: news2,
                  alt: "News Image 2",
                },
                {
                  image: news3,
                  alt: "News Image 3",
                },
              ],
            },
          ].map((news, index) => (
            <div className="col-lg-3 col-md-6 items d-rotate wow" key={index}>
              <div
                className="item-box bg md-mb50 rotate-text"
                onClick={() => handleOpenModal(news)}
              >
                <div className="icon">
                  <a>
                    <img src={news.images[0].image} alt={news.images[0].alt} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flr mt-30">
          <a className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right"></span>
          </a>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedNews && selectedNews.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedNews && (
            <>
              <div className="icon">
                <a className="newsandevents-modal">
                  <img src={selectedNews.image} alt={selectedNews.alt} />
                </a>
              </div>
              <p>{selectedNews.description}</p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default NewsandEvents;
